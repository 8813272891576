
import ContactsList from '@/components/ContactsList.vue'
import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'

@Component({
  components: {
    Main,
    ContactsList,
  },
  metaInfo: {
    title: 'Contacts',
  },
})
export default class Contacts extends Vue {}
