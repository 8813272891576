
import { Vue, Component } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { Customer } from '@/models/dto/Customer'
import { RawLocation } from 'vue-router'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import sidebar from '@/store/modules/sidebar'
import ContactSidebarDetail from '@/components/ContactSidebarDetail.vue'
import { EventBus } from '@/utils/eventBus'
import client from '@/services/customer'
import HoldUpModal from '@/components/HoldUpModal.vue'
import CreateContactSidebar from '@/components/CreateContactSidebar.vue'

@Component({
  components: { HoldUpModal, CUCollectionTable },
})
export default class ContactsList extends Vue {
  tableView = client.tableView
  tableItemCount = 0
  isModalOpen = false
  selectedContacts: Customer[] = []

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleContactSelected(customer: Customer): void {
    if (customer.customerId) {
      sidebar.push({
        component: ContactSidebarDetail,
        props: { userId: customer.customerId, showContactsTVButton: true },
        fab: true,
        on: { refresh: () => EventBus.$emit('refresh-tableview') },
      })
    }
  }

  handleAddContact(): void {
    sidebar.push({
      component: CreateContactSidebar,
      props: { displayBulkImport: true },
      on: {
        submit: (id: number) =>
          this.$router.push({
            name: 'contacts.detail',
            params: { id: String(id) },
          }),
      },
    })
  }

  handleDeleteContacts(customers: Customer[]): void {
    const snackbarMessage = 'Contacts deleted successfully!'
    const promises = customers
      .map(({ customerId }) => customerId)
      .map(client.delete)
    Promise.all(promises)
      .then(() => EventBus.$emit('refresh-tableview'))
      .then(() => EventBus.$emit('snackbar:success', snackbarMessage))
      .finally(() => (this.selectedContacts = []))
  }

  deleteContacts(customers: Customer[]): void {
    this.isModalOpen = true
    this.selectedContacts = customers
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.deleteContacts,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'fd46cf86-cebb-4f46-903d-60861a9311dd',
      text: 'Name',
      value: 'contacts-name',
      columnSlotComponent: CUDataTableLink,
      clickEvent: 'contacts:contact-selected',
      computedText: (row: Customer): string => formatFullName(row),
      linkLocation: (row: Customer): RawLocation => ({
        name: 'contacts.detail',
        params: { id: String(row.customerId) },
      }),
      filterable: true,
      filterBySearch: true,
      filterAsIs: true,
      filterProp: 'fullName',
      filterType: 'contains',
      sortProp: 'lastName',
    },
    {
      _t_id: '77b5c033-52c6-4426-b336-a51f9d3fe649',
      text: 'Email',
      value: 'email',
      filterBySearch: true,
      filterProp: 'email',
    },
    {
      _t_id: '50d62a84-86e6-4abd-886d-1e3418e057d8',
      text: 'Phone',
      value: 'phone',
      computedText: (row): string => {
        const { phoneExtension, phoneCountryKey } = row
        let phone = countryPhoneFormatFilter(row.phone, phoneCountryKey)
        if (phoneExtension) {
          phone = `${phone} ext. ${
            phoneExtension.length > 5
              ? `${phoneExtension.slice(0, 5)}...`
              : phoneExtension
          }`
        }
        return phone
      },
    },
    {
      _t_id: '85040d68-3989-43d2-bd24-2c577eba42c1',
      text: 'Company',
      value: 'customerAccountName',
    },
    {
      _t_id: '02b4f736-1ded-49b9-b2d1-3686a652e02f',
      text: 'Address',
      value: 'street1',
      filterable: false,
      sortable: false,
    },
    {
      _t_id: '81e772ce-3c0f-474b-8dad-26e7fae14c94',
      text: 'City',
      value: 'city',
      filterable: false,
      sortable: false,
    },
    {
      _t_id: 'eb9a3e27-ea56-424f-9980-9ce08b59d6c8',
      text: 'State',
      value: 'state',
      filterable: false,
      sortable: false,
    },
    {
      _t_id: '7fbc662d-8789-4d82-b011-d2ebee6cd354',
      text: 'Industry',
      value: 'industries',
    },
  ]

  refreshTableView(): void {
    EventBus.$emit('refresh-tableview')
  }

  mounted(): void {
    EventBus.$on('contacts:contact-selected', this.handleContactSelected)
    EventBus.$on('contact-sidebar:update', this.refreshTableView)
  }

  beforeDestroy(): void {
    EventBus.$off('contacts:contact-selected', this.handleContactSelected)
    EventBus.$off('contact-sidebar:update', this.refreshTableView)
  }
}
